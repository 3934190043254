html,
body {
  margin: 0;
}

html,
body,
#root {
  min-height: 100vh;
}

a {
  color: lime;
}